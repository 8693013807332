import React, { useState, useEffect } from "react";
import styled from "styled-components";
import detective from "./assets/detective.png";
import { textArray } from "./textArray";
import { color } from "./color";
import betaImage from "./assets/betaImage.png";
import {
  device,
  HeaderContainer,
  Title,
  Row,
  Subtitle,
  TextContainer,
  IconImage,
  TextWithSpaces,
  LeftButton,
  RightButton,
} from "./util";

// desktop design
const DesktopUI = () => {
  return (
    <HeaderContainer
      style={{
        padding: "0px",
      }}
    >
      {/* desktop design에서는 makecolumn이 없어야함 */}
      <DivideContainer
        style={{
          marginRight: "150px",
        }}
      >
        <LeftIconImage
          style={{
            marginLeft: "50px",
          }}
          src={betaImage}
          alt="Beta"
        />
        <TextContainer>
          <TextWithSpaces>
            <LeftTitle
              style={{
                textAlign: "center",
              }}
            >
              {textArray.leftLast[0]}
            </LeftTitle>
            <Subtitle
              style={{
                color: "black",
                width: "600px",
                textAlign: "center",
              }}
            >
              {textArray.leftLast[1]}
            </Subtitle>
          </TextWithSpaces>
        </TextContainer>
        <LeftButton
          onClick={() => {
            window.location.href = "https://forms.gle/1hTzqK7ohJc9xAjg9";
          }}
        >
          의뢰인 가입 문의
        </LeftButton>
      </DivideContainer>
      <DivideContainer style={{}}>
        <RightIconImage
          style={{
            marginLeft: "130px",
          }}
          src={betaImage}
          alt="Beta"
        />
        <TextContainer>
          <TextWithSpaces>
            <RightTitle
              style={{
                textAlign: "center",
              }}
            >
              {textArray.rightLast[0]}
            </RightTitle>
            <Subtitle
              style={{
                color: "black",
                width: "600px",
                textAlign: "center",
              }}
            >
              {textArray.rightLast[1]}
            </Subtitle>
          </TextWithSpaces>
        </TextContainer>
        <RightButton
          style={{
            marginLeft: "230px",
          }}
          onClick={() => {
            window.location.href = "https://forms.gle/4c9Xoo6vSMjQpfan6";
          }}
        >
          탐정 가입 문의
        </RightButton>
      </DivideContainer>
    </HeaderContainer>
  );
};

// mobile design
const MobileUI = () => {
  return (
    <HeaderContainer style={{}}>
      {/* desktop design에서는 makecolumn이 없어야함 */}
      <MakeColumn
        style={{
          padding: "10px",
          marginTop: "25px",
        }}
      >
        <LeftIconImage
          style={{
            marginLeft: "75px",
          }}
          src={betaImage}
          alt="Beta"
        />
        <DivideContainer>
          <TextContainer>
            <TextWithSpaces>
              <LeftTitle
                style={{
                  textAlign: "center",
                }}
              >
                {textArray.mobileLeftLastHeaderText}
              </LeftTitle>
              {/* <Subtitle>{textArray.leftLast[1]}</Subtitle> */}
              <Subtitle
                style={{
                  textAlign: "center",
                }}
              >
                {textArray.mobileLast[0]}
              </Subtitle>
            </TextWithSpaces>
          </TextContainer>
          <LeftButton
            onClick={() => {
              window.location.href = "https://forms.gle/1hTzqK7ohJc9xAjg9";
            }}
          >
            의뢰인 가입 문의
          </LeftButton>
        </DivideContainer>
        <RightIconImage src={betaImage} alt="Beta" />
        <DivideContainer>
          <TextContainer>
            <TextWithSpaces>
              <RightTitle
                style={{
                  textAlign: "center",
                }}
              >
                {textArray.rightLast[0]}
              </RightTitle>
              <Subtitle
                style={{
                  textAlign: "center",
                  color: "black",
                }}
              >
                {textArray.mobileLast[1]}
              </Subtitle>
            </TextWithSpaces>
          </TextContainer>
          <RightButton
            onClick={() => {
              window.location.href = "https://forms.gle/4c9Xoo6vSMjQpfan6";
            }}
          >
            탐정 가입 문의
          </RightButton>
        </DivideContainer>
      </MakeColumn>
    </HeaderContainer>
  );
};

function Fifth() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsDesktop(window.innerWidth >= 1024);
    });
  }, []);

  return isDesktop ? <DesktopUI /> : <MobileUI />;
}

export default Fifth;

const DivideContainer = styled.div`
  @media ${device.desktop} {
    width: 500px;
    height: 300px;
  }
  @media ${device.mobileXL} {
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
  }
`;

const LeftTitle = styled.h1`
  color: black;
  font-size: 36px;
  font-weight: bold;
  font-family: "CustomFont";
  @media ${device.mobileXL} {
    font-size: 28px;
  }
`;
const RightTitle = styled.h1`
  color: black;
  font-size: 36px;
  font-weight: bold;
  font-family: "CustomFont";
  @media ${device.mobileXL} {
    font-size: 28px;
  }
`;

const LeftIconImage = styled.img`
  width: 36px;
  height: 18px;
  margin-bottom: -20px;
`;
const RightIconImage = styled.img`
  width: 36px;
  height: 18px;
  margin-bottom: -20px;
  @media ${device.mobileXL} {
    margin-left: 62px;
  }
`;

const MakeColumn = styled.div`
  flex-direction: column;
  display: flex;
`;
