import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { color } from "./color";
import { textArray } from "./textArray";
import fifthImage from "./assets/fifthImage.png";
import contractImage from "./assets/contractImage.png";
import {
  HeaderContainer,
  device,
  Title,
  Row,
  Subtitle,
  TextContainer,
  Image,
  IconImage,
  MakeColumn,
} from "./util";

const DesktopUI = () => {
  return (
    <HeaderContainer
      style={{
        backgroundColor: color.white,
        marginLeft: "140px",
      }}
    >
      <Image src={fifthImage} alt="Contents" />
      <MakeColumn
        style={{
          marginLeft: "250px",
        }}
      >
        <IconImage src={contractImage} alt="Detective" />
        <TextContainer>
          <Title>{textArray.fifth[0]}</Title>
          <Subtitle
            style={{
              color: color.black,
            }}
          >
            {textArray.fifth[1]}
          </Subtitle>
        </TextContainer>
      </MakeColumn>
    </HeaderContainer>
  );
};
const MobileUI = () => {
  return (
    <HeaderContainer
      style={{
        backgroundColor: color.white,
      }}
    >
      <MakeColumn
        style={{
          alignItems: "center",
        }}
      >
        <IconImage src={contractImage} alt="Detective" />
        <TextContainer
          style={{
            textAlign: "center",
          }}
        >
          <Title>{textArray.fifth[0]}</Title>
          <Subtitle
            style={{
              color: color.black,
            }}
          >
            {textArray.fifth[1]}
          </Subtitle>
        </TextContainer>
        <Image src={fifthImage} alt="Contents" />
      </MakeColumn>
    </HeaderContainer>
  );
};
function Fifth() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsDesktop(window.innerWidth >= 1024);
    });
  }, []);
  return isDesktop ? <DesktopUI /> : <MobileUI />;
}

export default Fifth;
