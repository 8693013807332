import React from "react";
import styled from "styled-components";
import { color } from "./color";
import { device } from "./util";

const Footer = () => {
  const text = `서울특별시 서대문구 통일로 484\n서울시립대학교 공유캠퍼스 301-2`;
  const admText = `이메일 | tamkleadm@gmail.com\n대표자 | 최민기`;
  return (
    <Container>
      <MainText>Pladder(플래더)</MainText>
      <TextContainer>
        <SubText>{text} </SubText>
      </TextContainer>
      <TextContainer>
        <SubText>{admText}</SubText>
      </TextContainer>
      <WarningText>
        탐클은 통신판매의 당사자가 아닌 통신판매중개사업자로서 일반회원과
        탐정회원 간의 업무 의뢰, 계약 등에 직접 개입하거나 관여하지 않으며,
        회원간의 중개시스템만 제공합니다.
      </WarningText>
      <CopyrightText>
        Copyright © 2024 Pladder Corp. All rights reserved.
      </CopyrightText>
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  background-color: ${color.black};
  padding: 20px;
  padding-left: 200px;
  justify-content: center;
  align-items: center;
  @media ${device.mobileXL} {
    padding: 5px;
    padding-left: 20px;
  }
`;

const MainText = styled.h1`
  color: ${color.white};
  font-size: 24px;
  font-family: "CustomFont";
  @media ${device.mobileXL} {
    font-size: 18px;
  }
`;

const SubText = styled.p`
  color: ${color.white};
  font-size: 16px;
  font-family: "CustomFont";

  @media ${device.mobileXL} {
    font-size: 15px;
  }
`;

const CopyrightText = styled.p`
  color: ${color.white};
  font-size: 8px;
  font-family: "CustomFont";

  @media ${device.mobile} {
    font-size: 13px;
  }
`;

const TextContainer = styled.div`
  white-space: pre-line;
`;

const WarningText = styled.p`
  color: #828289;
  font-size: 16px;
  font-family: "CustomFont";

  @media ${device.mobileXL} {
    font-size: 12px;
  }
`;
