import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { color } from "./color";
import { textArray } from "./textArray";
import thirdImage from "./assets/thirdImage.png";
import clockImage from "./assets/clockImage.png";
import {
  HeaderContainer,
  Row,
  Title,
  Subtitle,
  TextContainer,
  Image,
  IconImage,
  MakeColumn,
  device,
} from "./util";

const DesktopUI = () => {
  return (
    <HeaderContainer
      style={{
        backgroundColor: color.white,
        marginLeft: "50px",
      }}
    >
      <Image style={{}} src={thirdImage} alt="Contents" />
      <MakeColumn
        style={{
          marginLeft: "250px",
        }}
      >
        <IconImage src={clockImage} alt="Clock" />
        <TextContainer>
          <Title>{textArray.third[0]}</Title>
          <Subtitle
            style={{
              color: color.black,
            }}
          >
            {textArray.third[1]}
          </Subtitle>
        </TextContainer>
      </MakeColumn>
    </HeaderContainer>
  );
};

const MobileUI = () => {
  return (
    <HeaderContainer
      style={{
        backgroundColor: color.white,
        marginLeft: device.mobileXL ? 0 : "550px",
      }}
    >
      <MakeColumn
        style={{
          alignItems: "center",
        }}
      >
        <IconImage src={clockImage} alt="Clock" />
        <TextContainer
          style={{
            textAlign: "center",
          }}
        >
          <Title>{textArray.third[0]}</Title>
          <Subtitle
            style={{
              color: color.black,
            }}
          >
            {textArray.third[1]}
          </Subtitle>
        </TextContainer>
        <Image style={{}} src={thirdImage} alt="Contents" />
      </MakeColumn>
    </HeaderContainer>
  );
};

function Third() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsDesktop(window.innerWidth >= 1024);
    });
  }, []);

  return isDesktop ? <DesktopUI /> : <MobileUI />;
}

export default Third;
