import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { color } from "./color";
import { textArray } from "./textArray";
import fourthImage from "./assets/fourthImage.png";
import icon_check from "./assets/icon_check.png";
import {
  HeaderContainer,
  DivideContainer,
  Title,
  Row,
  Subtitle,
  TextContainer,
  Image,
  IconImage,
} from "./util";

function Fourth() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsDesktop(window.innerWidth >= 1024);
    });
  }, []);
  return (
    <HeaderContainer style={{ backgroundColor: color.grey }}>
      <Row>
        <DivideContainer>
          <IconImage src={icon_check} alt="Detective" />
          <TextContainer>
            <Title>
              {isDesktop
                ? textArray.fourth[0]
                : textArray.mobileFourthHeaderText}
            </Title>
            <Subtitle
              style={{
                color: color.black,
              }}
            >
              {textArray.fourth[1]}
            </Subtitle>
          </TextContainer>
        </DivideContainer>
        <Image src={fourthImage} alt="Detective" />
      </Row>
    </HeaderContainer>
  );
}

export default Fourth;
