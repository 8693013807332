import React from "react";
import styled from "styled-components";
import { color } from "./color";
import { textArray } from "./textArray";
import secondImage from "./assets/secondImage.png";
import icon_lock from "./assets/icon_lock.png";
import {
  device,
  HeaderContainer,
  DivideContainer,
  Title,
  Row,
  Subtitle,
  TextContainer,
  Image,
} from "./util";

function Second() {
  return (
    <HeaderContainer
      style={{
        backgroundColor: color.grey,
      }}
    >
      <Row>
        <DivideContainer>
          <IconImage src={icon_lock} alt="Lock" />
          <TextContainer>
            <Title>{textArray.second[0]}</Title>
            <Subtitle
              style={{
                color: color.black,
              }}
            >
              {/* {textArray.second[1]} */}
              {textArray.mobileSecondSubText}
            </Subtitle>
          </TextContainer>
        </DivideContainer>
        <Image src={secondImage} alt="Contents" />
      </Row>
    </HeaderContainer>
  );
}

export default Second;

const IconImage = styled.img`
  width: 24px;
  height: 24px;

  @media ${device.desktop} {
    width: 24px;
    height: 24px;
    margin-bottom: -20px;
  }
`;
