import React, { useState, useEffect } from "react";
import styled from "styled-components";
import detective from "./assets/detective.png";
import { textArray } from "./textArray";
import { color } from "./color";
import {
  device,
  HeaderContainer,
  DivideContainer,
  Subtitle,
  TextContainer,
  Button,
  Row,
  Image,
  Title,
  screenWidth,
} from "./util";

function First() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsDesktop(window.innerWidth >= 1024);
    });
  }, []);
  return (
    <HeaderContainer
      style={{
        backgroundColor: color.main,
        padding: isDesktop ? "0px" : "20px",
      }}
    >
      <Row>
        <DivideContainer
          style={{
            marginRight: isDesktop ? "-50px" : "0",
            marginLeft: isDesktop ? "100px" : "0",
            width: isDesktop ? "500px" : "100%",
            height: isDesktop ? "100%" : "100%",
            alignItems: isDesktop ? "flex-start" : "center",
            justifyContent: isDesktop ? "center" : "center",
          }}
        >
          <TextContainer>
            <Title>{textArray.first[0]}</Title>
            <Subtitle>{textArray.first[1]}</Subtitle>
          </TextContainer>
          <Button
            onClick={() => {
              window.location.href = "https://forms.gle/1hTzqK7ohJc9xAjg9";
            }}
          >
            탐클 시작하기
          </Button>
        </DivideContainer>
        <Image
          style={{
            ...(isDesktop
              ? { height: 380, width: 350 }
              : {
                  height: "100%",
                  width: screenWidth,
                  marginBottom: -20,
                }),
          }}
          src={detective}
          alt="Detective"
        />
      </Row>
    </HeaderContainer>
  );
}

export default First;
