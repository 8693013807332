import { createGlobalStyle } from "styled-components";

import PretendardWoff2 from "../assets/fonts/Pretendard-Regular.woff2";
import PretendardWoff from "../assets/fonts/Pretendard-Regular.woff";
import PretendardTtf from "../assets/fonts/Pretendard-Regular.ttf";
import PlaywriteMX from "../assets/fonts/PlaywriteMX-Regular.ttf";

import Bold_PretendardWoff2 from "../assets/fonts/Pretendard-ExtraBold.woff2";
import Bold_PretendardWoff from "../assets/fonts/Pretendard-ExtraBold.woff";
import Bold_PretendardTtf from "../assets/fonts/Pretendard-ExtraBold.ttf";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'CustomFont';
    src: url(${PretendardWoff2}) format('woff2'),
         url(${PretendardWoff}) format('woff'),
         url(${PretendardTtf}) format('truetype');
         url(${PlaywriteMX}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  body {
    font-family: 'CustomFont'
  }
    @font-face {
    font-family: 'CustomBoldFont';
    src: url(${Bold_PretendardWoff2}) format('woff2'),
         url(${Bold_PretendardWoff}) format('woff'),
         url(${Bold_PretendardTtf}) format('truetype');
`;

export default GlobalStyle;
