import styled from "styled-components";
import { color } from "./color";

export const sizes = {
  mobileS: "320px", // 작은 모바일 (iPhone SE 등)
  mobileM: "375px", // 중간 모바일 (iPhone 8 등)
  mobileL: "425px", // 큰 모바일 (iPhone 8 Plus 등)
  mobileXL: "480px", // 더 큰 모바일 (iPhone 12 Pro Max 등)
  tablet: "768px", // 태블릿 (iPad 등)
  laptop: "1024px", // 작은 랩탑 (12인치 랩탑 등)
  laptopL: "1440px", // 큰 랩탑 (15인치 이상 랩탑 등)
  desktop: "2560px", // 데스크탑 (대형 모니터 등)
};

export const device = {
  mobileS: `(max-width: ${sizes.mobileS})`,
  mobileM: `(max-width: ${sizes.mobileM})`,
  mobileL: `(max-width: ${sizes.mobileL})`,
  mobileXL: `(max-width: ${sizes.mobileXL})`,
  tablet: `(max-width: ${sizes.tablet})`,
  laptop: `(max-width: ${sizes.laptop})`,
  laptopL: `(max-width: ${sizes.laptopL})`,
  desktop: `(max-width: ${sizes.desktop})`,
};

export const screenWidth = window.innerWidth;

export const DivideContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 150px;
  width: 500px;

  @media ${device.desktop} {
    margin-right: 0;
  }
  @media ${device.mobileXL} {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
`;

export const HeaderContainer = styled.div`
  background-color: ${color.main};
  display: flex;
  justify-content: center;

  @media ${device.desktop} {
    padding: 50px;
  }
  @media ${device.mobileXL} {
    padding: 20px;
  }
`;

export const Title = styled.h1`
  color: black;
  font-size: 36px;
  font-family: "CustomFont", sans-serif;

  @media ${device.desktop} {
    font-size: 36px;
  }
  @media ${device.mobileXL} {
    font-size: 32px;
    text-align: center;
  }
`;

export const Subtitle = styled.p`
  color: ${color.white};
  font-size: 18px;
  font-family: "CustomFont", sans-serif;
  width: 200px;

  @media ${device.desktop} {
    width: 100%;
  }
  @media ${device.mobileXL} {
    font-size: 15px;
    width: 100%;
    text-align: center;
    line-height: 1.25;
  }
`;

export const Row = styled.div`
  display: flex;
  @media ${device.desktop} {
  }
  @media ${device.mobileXL} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media ${device.mobileL} {
    flex-direction: column;
  }
`;

export const Image = styled.img`
  width: 250px;
  height: 250px;

  @media ${device.desktop} {
    height: 250px;
    width: 180px;
  }
  @media ${device.mobileXL} {
    height: 200px;
    width: 130px;
    margin-top: 20px;
  }
`;

export const Button = styled.button`
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 150px;
  margin-top: 20px;
  font-family: "CustomFont", sans-serif;
  @media ${device.mobileXL} {
    width: 230px;
    height: 60px;
    border-radius: 10px;
    font-size: 24px;
  }
`;

export const TextContainer = styled.div`
  white-space: pre-line;
  @media ${device.mobileXL} {
  }
`;

export const IconImage = styled.img`
  width: 30px;
  height: 30px;
  margin-bottom: -20px;

  @media ${device.mobileXL} {
    width: 30px;
    height: 30px;
    margin-bottom: -10px;
  }
`;

export const MakeColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextWithSpaces = styled.p`
  white-space: pre-wrap;
`;

const ButtonBase = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  font-family: "CustomFont";
  width: 140px;

  @media ${device.mobileXL} {
    width: 100%;
    font-size: 18px;
    height: 40px;
  }
`;

export const LeftButton = styled(ButtonBase)`
  background-color: ${color.white};
  color: ${color.black};
  margin-left: 150px;

  @media ${device.desktop} {
    font-size: 12px;
    width: 120px;
    margin-left: 200px;
  }
  @media ${device.mobileXL} {
    width: 70%;
    margin-left: 0;
    font-size: 18px;
  }
`;

export const RightButton = styled(ButtonBase)`
  background-color: #000;
  color: #fff;
  width: 120px;
  margin-left: 180px;

  @media ${device.desktop} {
    font-size: 12px;
  }
  @media ${device.mobileXL} {
    width: 70%;
    margin-left: 0;
    font-size: 18px;
  }
`;
